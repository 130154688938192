.courses__image_big {
    width: 96%;
    border: 2px solid #000;
    margin: 5px auto 0;
    padding: 0;
    grid-column: 1/ 4;
}

@media screen and (max-width: 990px) {
    .courses__image_big {
        width: 94%;
        margin: auto;
        grid-column: 1/ 3;
    }
}