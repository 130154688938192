.lectures__image_big {
    width: 97%;
    margin: 5px auto 0;
    grid-column:  1 / 3;
    
    border: 1px solid #000;
    border-radius: 0px;
    padding: 0;
}

@media screen and (max-width: 990px) {
    .lectures__image_big {
        width: 97%;
        margin: 5px auto 0;
        grid-column:  1 / 3;
        border: 1px solid #000;
        border-radius: 0px;
        padding: 0;
    }    
}
