.card__overlay-active {
    position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    background-color: rgba(238, 255, 0, 0);
    border-radius: 25px;
    border: 2px solid #edb518;;
    margin: 0;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 7px 7px 5px rgba(70, 70, 70, 1);
    opacity: 0;
    transition: 0.7s;
    z-index: 1;
}

.card__overlay-active:hover {
    opacity: 1;
    
}