.courses__image {
    width: 90%;
    border: 1px solid #000;
    margin: 8px auto 0;
    padding: 0;
}

@media screen and (max-width: 990px) {
    .courses__image {
        margin: auto;
    }

    .courses__image:nth-of-type(3) {
        grid-row: 3 / 5;
    }
}