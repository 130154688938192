.card__price {
    color: #fff;
    font-family: 'Varela_Round', 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 26px;
    z-index: 1;
}

@media screen and (max-width: 400px) {
    .card__price {
        font-size: 22px;
        line-height: 24px;
    }
}