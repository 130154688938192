.accessibility-statement__close {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
        top: 12px;
        left: 9px;
}

.accessibility-statement__close-icon {
    width: 25px;
}