.header__overlay {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: relative;
        
}

.header__overlay:hover {
    background-color: rgba(0, 0, 0, 0.2);
}