.courses__images-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 990px) {
    .courses__images-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px 0;
    }
}