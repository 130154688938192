.header__language-button {
    font-size: 18px;
    line-height: 20px;
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    background-color: transparent;
    border: 1px dotted #fff;
    border-radius: 25px;
    margin: 0 auto;
    padding: 5px 15px;
    color: #fff;
    cursor: pointer;
    transition: 0.7s;
    position: absolute;
        bottom: 15px;
}

.header__language-button:hover {
    background-color: rgba(255, 255, 255, 1);
    color: rgb(0, 0, 0);
    box-shadow: 5px 5px 3px #444;
    transform: scale(1.0, 1);
}