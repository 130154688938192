.card__overlay {
    position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    margin: 0;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 1;
    transition: 0.7s;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.card__overlay:hover {
    opacity: 1;
    box-shadow: 7px 7px 5px rgba(0, 0, 100, 1);
}