.accessibility-statement__text {
    font-family: 'Varela_Round', 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 26px;
    color: #000407;
    margin: 10px 0 0;
}

.accessibility-statement__text:last-of-type {
    margin-bottom: 10px;
}