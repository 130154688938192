.price-list__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

@media screen and (max-width: 1370px) {
    .price-list__container {
        grid-template-columns: repeat(2, 1fr);
    }    
}

@media screen and (max-width: 725px) {
    .price-list__container {
        grid-template-columns: repeat(1, 1fr);
    }    
}