.about__image {
    width: 450px;
    max-height: 300px;
    border: 2px solid #000;
    box-shadow: 3px 3px 4px #444;
    margin: 0 15px 0 0;
    padding: 0;
    grid-area: image;
    
}

@media screen and (max-width: 990px) {
    .about__image {
        margin: 0 auto 25px
    }
}

@media screen and (max-width: 550px) {
    .about__image {
        width: 350px;
    }
}

@media screen and (max-width: 450px) {
    .about__image {
        width: 300px;
    }
}

@media screen and (max-width: 370px) {
    .about__image {
        width: 270px;
    }
}
