.footer__button {
    margin: 0 0 20px;
    padding: 8px 30px;
    background-color: transparent;
    border: 2px solid #f5f7f7;
    border-radius: 30px;
    font-family: 'Varela_Round', Arial, Helvetica, sans-serif, 'Rubik';
    font-size: 18px;
    line-height: 20px;
    color: #f5f7f7;
    cursor: pointer;
}

.footer__button:hover {
    background-color: #00000077;
}