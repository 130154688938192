.navigation__link-item {
    padding: 0;
    margin: 0px auto;
}

@media screen and (max-width: 990px) {
    .navigation__link-item {
        width: 90%;
    }
    
}