.navigation {
    position: fixed;
        top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    background-color: rgba(238, 180, 23, 1);
    width: 100%;
    border-bottom: 0px solid rgba(237, 181, 24, 0.05);
    transition: 0.5s;
    z-index: 1;
}

.navigation:hover {
    background-color: rgba(237, 181, 24, 1);
}

@media screen and (max-width: 990px) {
    .navigation {
        background-color: rgb(238, 180, 23, );
        border: 0;
        flex-direction: column;
    }
}