.navigation__button_active {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 22px;
    background-color: rgba(255, 255, 255, 0);
    color: #79031d;
    border: 0;
    border-bottom: 2px solid #79031d;
    margin: 0 30px 0 0;
    padding: 15px 0;
    cursor: pointer;
    transition: 0.7s;
}

@media screen and (max-width: 990px) {
    .navigation__button_active {
        width: 100%;
        margin: 0px auto;
        padding: 4px 0;
        border: 0;
        color: #79031d;
    }

    .navigation__button_active:hover {
        transform: scale(1, 1);
    }
}
