.app {
  position: relative;
  /* width: 100%;
  max-width: 1500px; */
  margin: 0 auto;
  padding: 0;
  background-color: #f5f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
}