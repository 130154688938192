.header__background {
    width: 100%;
    max-width: 1310px;
    height: 100vh;
    margin: 0 auto;
    background-image: url('../../../images/cover-photo01-wide.jpg');
    /* background-position: center; */
    background-position: center 51px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

/* @media screen and (max-width: 1600px) {
    .header__background {
        max-width: 950px;
        
    }
} */

@media screen and (max-width: 980px) {
    .header__background {
        background-image: url('../../../images/cover-photo01-mobile.jpg');
        background-position: center;
    }
}
