.footer {
    /* background-color: #000407; */
    background-color: rgb(70, 40, 26);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}
