.accessibility-statement {
    position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    z-index: 2;
    background-color: #000000dd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
}