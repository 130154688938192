.navigation__button {
    font-family: 'Varela_Round', 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 22px;
    background-color: transparent;
    border: 0;
    margin: 0 30px 0 0;
    padding: 15px 0;
    color: rgb(0, 4, 7);
    cursor: pointer;
    transition: 0.3s;
}

.navigation__button:hover {
    color: #79031d;
    padding: 13px 0;
    border-bottom: 2px solid rgb(121, 3, 29);
}

@media screen and (max-width: 990px) {
    .navigation__button {
        width: 100%;
        margin: 0 auto;
        padding: 4px 0;
    }

    .navigation__button:hover {
        border-bottom: 0px solid #000407;
        color: rgba(255, 255, 255, 0.5);
    }
}
