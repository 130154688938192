.contact__link {
    margin: 0;
    padding: 0;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    color: #79031d;
}

.contact__link:hover {
    color: #edb518;
}