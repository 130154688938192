.header__title {
    margin: 0 0 70px;
    width: 90%;
    color: #f5f7f7;
    font-family: 'Varela_Round', 'Rubik-bold', 'Yehuda', 'Arial';
    font-size: 65px;
    line-height: 70px;
    text-align: center;
    letter-spacing: 0.1rem;
    transition: 1s;
}
