.contact__icons {
    margin: 0;
    padding: 0;
    background-color: rgba(237, 181, 24, 0.9);
    border: 2px solid #000;
    border-radius: 15px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 2;
}