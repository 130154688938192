.accessibility-statement__container {
    width: 90%;
    max-width: 850px;
    height: 85vh;
    background-color: #fff;
    border: 5px solid #79031d;
    border-radius: 10px;
    margin: 30px 0;
    padding: 10px;
    position: absolute;
}

@media screen and (max-width: 400px) {
    .accessibility-statement__container {
        width: 83%;
        height: 85vh;
    }
}