.about__description {
    font-family: 'Varela_Round', 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 22px;
    color: #000407;
    grid-area: description;
}

@media screen and (max-width: 400px) {
    .about__description {
        font-size: 16px;
        line-height: 20px;
    }
}