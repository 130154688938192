.price-list__content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 70%;
}

@media screen and (max-width: 990px) {
    .price-list__content {
        width: 90%;
    }
}