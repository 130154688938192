.card {
    width: 280px;
    height: 280px;
    background-color: #aaa;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 25px;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.7s;
    z-index: 0;
}

.card:hover {
    transform: scale(1.05, 1.05);
}

@media screen and (max-width: 400px) {
    .card {
        width: 240px;
        height: 240px;
    }
}