.loader {
    background-color: #ddd;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.loader__text {
    color: #000;
    font-family: 'Varela_Round', 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 24px;
    
}