.lectures__image {
    width: 95%;
    border: 1px solid #000;
    border-radius: 0px;
    margin: 8px auto 0;
    padding: 0;
}

@media screen and (max-width: 990px) {
    .lectures__image {
        margin: 5px auto 0;
    }
}