.footer__text {
    font-family: 'Varela_Round', Arial, Helvetica, sans-serif, 'Rubik';
    font-size: 18px;
    line-height: 20px;
    color: #f5f7f7;
    margin: 15px 0 20px;
}

@media screen and (max-width: 400px) {
    .footer__text {
        font-size: 15px;
        line-height: 17px;
        margin: 30px 0 10px;
    }
}
