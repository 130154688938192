.card__title {
    color: #fff;
    font-family: 'Varela_Round', 'Rubik-bold', Arial, Helvetica, sans-serif;
    font-size: 34px;
    line-height: 38px;
    z-index: 1;
    text-align: center;
    margin: 0;
}

@media screen and (max-width: 400px) {
    .card__title {
        font-size: 28px;
        line-height: 30px;
    }
}