.about__content {
    width: 70%;
    margin: 0 auto 50px;
}

.about__main {
    display: grid;
    grid-template-areas: "description image";
}

@media screen and (max-width: 1405px) {
    .about__content {
        width: 80%;
        /* flex-direction: row-reverse; */
    }
}

@media screen and (max-width: 1205px) {
    .about__content {
        width: 85%;
        /* flex-direction: row-reverse; */
    }
}

@media screen and (max-width: 990px) {
    .about__main {
        width: 100%;
        grid-template-areas: "image"
                             "description";
    }

}
