.navigation__icon {
    background-color: rgba(255, 255, 255, 0.0);
    border-radius: 50%;
    border: 0;
    margin: 10px;
    padding: 5px;
    cursor: pointer;
}

.navigation__icon-symbol {
    width: 25px;
    color: #fff;
}