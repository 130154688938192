.contact__button {
    font-family: 'Varela_Round', 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 22px;
    background-color: #edb518;
    border: 0px solid #fff;
    border-radius: 15px;
    margin: 0 auto;
    padding: 15px;
    color: #79031d;
    box-shadow: 4px 4px 3px #444444;
    cursor: pointer;
    z-index: 2;
    transition: 0.3s;
}

.contact__button:hover {
    background-color: #79031d;
    color: #edb518;
    box-shadow: 1px 1px 3px #3f3e3e;
    transform: scale(1, 1);
}
