.lectures__content {
    width: 70%;
    margin: 0 auto 50px;
}

@media screen and (max-width: 990px) {
    .lectures__content {
        width: 90%;     
   }
}
