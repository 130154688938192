.header__subtitle {
    margin: 0 0 15px;
    width: 90%;
    font-family: 'Varela_Round', 'Rubik', 'Yehuda', 'Arial';
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #f5f7f7;
    letter-spacing: 0.1rem;
    transition: 1s;
}