.courses__video {
    width: 1100px;
    height: 617px;
    margin: 5px auto;
    border: 0px solid #f00;
    display: flex;
    justify-content: center;
    /* background-color: #f00; */
}

@media screen and (max-width: 1600px) {
    .courses__video {
        width: 900px;
        height: 506px;
    }
}

@media screen and (max-width: 1310px) {
    .courses__video {
        width: 800px;
        height: 450px;
    }
}

@media screen and (max-width: 1170px) {
    .courses__video {
        width: 650px;
        height: 366px;
    }
}

@media screen and (max-width: 990px) {
    .courses__video {
        width: 750px;
        height: 422px;
    }
}

@media screen and (max-width: 860px) {
    .courses__video {
        width: 600px;
        height: 338px;
    }
}

@media screen and (max-width: 740px) {
    .courses__video {
        width: 550px;
        height: 309px;
    }
}

@media screen and (max-width: 650px) {
    .courses__video {
        width: 450px;
        height: 253px;
    }
}

@media screen and (max-width: 530px) {
    .courses__video {
        width: 350px;
        height: 197px;
    }
}

@media screen and (max-width: 415px) {
    .courses__video {
        width: 300px;
        height: 167px;
    }
}

@media screen and (max-width: 350px) {
    .courses__video {
        width: 90%;
        /* height: 167px; */
    }
}