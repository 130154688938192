.courses__text {
    font-family: 'Varela_Round', 'Rubik', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 22px;
    color: #000407;
    margin: 40px 0 15px;
}

@media screen and (max-width: 400px) {
    .courses__text {
        font-size: 18px;
        line-height: 20px;
    }
}