.navigation__links {
    list-style: none;
    padding: 0;
    width: 80%;
    margin: 0 0 0 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media screen and (max-width: 990px) {
    .navigation__links {
        flex-direction: column;
        margin: 0 0 10px;
        width: 100%;
        opacity: 1;
    }
}
