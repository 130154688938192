.every-section__title {
    width: 300px;
    text-align: center;
    font-family: 'Varela_Round', 'Rubik-bold', Arial, Helvetica, sans-serif;
    font-size: 34px;
    line-height: 36px;
    color: #79031d;
    margin: 0;
    padding: 0;
    z-index: 0;
    background-color: #f5f7f7;   
}

@media screen and (max-width: 990px) {
    .every-section__title {
        width: 170px;
    }
}